// Validators
const validationMessage = {
  required: 'Povinné pole',
  minChars: 'Minimálny počet znakov',
};

export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const required = (value) => (value ? undefined : validationMessage.required);

export const minChars = (min) => (value) =>
  value && value.length < min ? `${validationMessage.minChars} ${min}` : undefined;

export const equalTo = (test, message) => (value) => (value && value !== test ? message : undefined);

// Select
export const createOption = (name) => ({
  value: name,
  label: name,
});
